{
  "name": "epsolutely",
  "version": "0.9.7",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "build-dev": "ng build --configuration=development",
    "unused-build-prod": "ng build --configuration=production",
    "copy-dev": "node transfer.js dev",
    "unused-copy-prod": "node transfer.js prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.1",
    "@angular/common": "^17.3.1",
    "@angular/compiler": "^17.3.1",
    "@angular/core": "^17.3.1",
    "@angular/forms": "^17.3.1",
    "@angular/platform-browser": "^17.3.1",
    "@angular/platform-browser-dynamic": "^17.3.1",
    "@angular/router": "^17.3.1",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@zxing/browser": "^0.1.4",
    "@zxing/library": "^0.20.0",
    "@zxing/ngx-scanner": "^17.0.1",
    "bootstrap": "^5.3.3",
    "moment": "^2.30.1",
    "ngx-bootstrap": "^12.0.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "~7.8.1",
    "sweetalert2": "^11.9.0",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular/cli": "^17.3.1",
    "@angular/compiler-cli": "^17.3.1",
    "@types/node": "^18.19.7",
    "node-ssh": "^13.1.0",
    "typescript": "~5.2.2",
    "zip-a-folder": "^3.1.3"
  }
}
